<script setup lang="ts">
import { usePropertyStore } from '~/stores/property'

const propertyStore = usePropertyStore()

const { favourites } = storeToRefs(propertyStore)

const properties = computed(() => {
  // filter out if the property slug matches the recently viewed property slug
  // filter out any properties without a title, price, image or slug
  return favourites.value
    .filter(property => property?.title && property?.price && property.images && property.slug)
})

const removeProperty = (slug: string) => {
  propertyStore.removeFavourite(slug)
}
</script>

<template>
  <UiFader
    v-if="properties && properties.length"
    class=""
  >
    <FeaturesCarousel>
      <template #button>
        <UiBtn
          variant="link"
          to="/browse/favourites"
          color="forest"
          size="lg"
          label="Favourites"
          class="mt-1 mr-4"
        />
      </template>
      <div
        v-for="property in properties"
        :key="property?.slug.current"
        class="snap-start lg:last:mr-4 magic-padding shrink-0 w-5/6 md:w-auto relative"
      >
        <UButton
          v-if="property?.slug?.current"
          color="white"
          variant="solid"
          size="sm"
          icon="i-heroicons-x-mark-20-solid"
          class=" opacity-40 absolute top-1 right-1 z-20 transition-opacity duration-300 ease-in-out hover:opacity-100"
          @click="removeProperty(property.slug.current)"
        />
        <PropertyCard
          :property="property"
          class="w-full md:w-[320px]"
        />
      </div>
    </FeaturesCarousel>
  </UiFader>
</template>
